
import React, { useState } from "react";
import {
    ReactAux, MainContainerHeader, ActionContainer,
    Button, ActionContainerHeader, ActionContainerCrumbs, MainContainerScrollable, Toolbar, ImageCard, ImageCardGrid, ImageCardPreview,
    ImageCardProps, Input, Label, Radio, Select, FileUpload, formatFileSize, Modal, ModalScrollableContainer,
    ConfirmationDialog
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from "../helpers/helpers";


const DemoModalImageCard = () => {
    const selectOptions = [
        { value: '1', label: 'Option 1', isDisabled: true },
        { value: '2', label: 'Option 2', secondCode: 'code-123' },
        { value: '3', label: 'Option 3', isSomething: false },
        { value: '4', label: 'Option 4' }
    ];

    const [imageCardList, setImageCardList] = useState([
        { src: `${process.env.PUBLIC_URL}/img/show-image.jpeg`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/vanguard-bank-logo.jpg`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/ion-bank-logo.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/ovation-bank-logo.jpg`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/ovation-global-dmc-logo.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/first-state-com-bank-logo.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/JackHenryLending_LoanVantage_Logo_navy.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
    ]);
    const [selectedImage, setSelectedImage] = useState<ImageCardProps['image'] | null>(null);
    const [infoItems, setInfoItems] = useState([
        { title: 'File name:', description: 'First_republic_bank.jpg' },
        { title: 'File Size:', description: '4.08 Mb' },
        { title: 'File Type:', description: 'image/png' },
        { title: 'URL:', description: 'https://loanvantage.dev/IBS/develop/publiccdn/api/branding/IBS/image/Equal_Housing_Logo.png', isLink: true },
    ]);
    const [selectValue, setSelectValue] = useState('');
    const [isVisible, setIsVisble] = useState(false);
    const [showConfigrm, setShowConfirmation] = useState(false)

    const onSelectImage = (image: ImageCardProps['image']) => {
        setSelectedImage(selectedImage && selectedImage.src === image?.src ? null : image);
        onSetPreviewItems(image);
    }

    const onDeleteImage = () => {
        setImageCardList(imageCardList.filter((image) => image.src !== selectedImage?.src))
        setSelectedImage(null)
        onCLoseConfirmation();
    }

    const onCLoseConfirmation = () => {
        setShowConfirmation(false)
    }

    const onSetPreviewItems = (image: ImageCardProps['image']) => {
        setInfoItems([
            { title: 'File name:', description: image?.name || '' },
            { title: 'File Size:', description: image?.size || '' },
            { title: 'File Type:', description: image?.format || '' },
            { title: 'URL:', description: image?.src || '', isLink: true },
        ])
    }

    const onAddNewImage = (fileData: any) => {
        const { name, size, type } = fileData.file;

        const img = new Image();
        // set image url
        img.src = window.URL.createObjectURL(fileData.file);
        // get image dimentions
        img.onload = function () {
            const width = img.width;
            const height = img.height;
            const newImage = {
                src: img.src,
                name: name,
                size: formatFileSize(size),
                resolution: `${width}x${height}`,
                format: type
            };

            setSelectedImage(newImage);
            onSetPreviewItems(newImage);
            setImageCardList([
                newImage,
                ...imageCardList
            ])
        };

    }

    const onCloseModal = () => {
        setIsVisble(false);
    }

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Home', 'Online', 'Media Library']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Button dataUI={getDataUI()} className="mt-m" onClick={() => setIsVisble(true)}>Choose Sign In Background Image</Button>
            </MainContainerScrollable>

            <Modal dataUI={getDataUI()} title="Choose Sign In Background Image" isVisible={isVisible} size="L+" onClose={onCloseModal}>
                <Toolbar
                    dataUI={getDataUI()}
                    className="mb-m"
                    leftSide={
                            <div className="d-flex mr-m align-self-start">
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled={!selectedImage} onClick={onCloseModal}>Save</Button>
                                <Button dataUI={getDataUI()} icon="fal fa-times-circle" btnType="secondary" onClick={onCloseModal} >Cancel</Button>
                            </div>
                    }
                    rightSide={
                        <div className="d-flex flex-wrap gap-m">
                            <Input placeholder="Search" icon="fal fa-search" className="width-m" dataUI={getDataUI()} />
                            <div className="d-flex">
                                <Label className="text-bold mr-s" dataUI={getDataUI()}>Show:</Label>
                                <Radio className="mr-s" name="radio-1" dataUI={getDataUI()}>Images</Radio>
                                <Radio className="mr-s" name="radio-1" dataUI={getDataUI()}>Favicons</Radio>
                                <Radio name="radio-1" dataUI={getDataUI()}>All</Radio>
                            </div>
                            <div className="d-flex">
                                <Label className="text-bold mr-s" dataUI={getDataUI()}>Sort:</Label>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    options={selectOptions}
                                    value={selectValue}
                                    placeholder="Select..."
                                    onChange={(obj: any) => {
                                        setSelectValue(obj.value);
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
                <div className="d-flex height-full">
                    <div className="flex-full-height width-full mr-m">
                        <ModalScrollableContainer>
                            <ImageCardGrid dataUI={getDataUI()}>
                                <FileUpload
                                    dataUI={getDataUI()}
                                    className="d-flex justify-content-center flex-column align-items-center"
                                    visualType="large"
                                    onChange={(files: any) => {
                                        onAddNewImage(files[0])
                                    }}
                                    supportedFormats=".png,.jpg,.jpeg"
                                    isMultiple
                                />
                                {
                                    imageCardList.map((image, i) => {
                                        return (
                                            <ImageCard
                                                dataUI={getDataUI()}
                                                key={i}
                                                image={image}
                                                onClick={() => {
                                                    onSelectImage(image);
                                                }}
                                                selected={image?.src === selectedImage?.src}
                                                selectIndicator
                                            />
                                        )
                                    })
                                }
                            </ImageCardGrid>
                        </ModalScrollableContainer>
                    </div>

                    <div className="flex-full-height">
                        <ModalScrollableContainer>
                            <ImageCardPreview
                                dataUI={getDataUI()}
                                title="Image Info"
                                imgSrc={selectedImage?.src}
                                infoItems={infoItems}
                            >

                                <ConfirmationDialog
                                    show={showConfigrm}
                                    render={() => <>
                                        <p className="mb-m">Are you sure you want to delete this image?</p>
                                        <Toolbar dataUI={getDataUI()} leftSide={<>
                                            <Button onClick={onDeleteImage} btnType="primary" icon='fal fa-trash' className="mr-s" dataUI={getDataUI()}>
                                                Yes, Delete
                                            </Button>
                                            <Button onClick={onCLoseConfirmation} btnType="secondary" icon='fal fa-times-circle' className="mr-s" dataUI={getDataUI()}>
                                                Cancel
                                            </Button>
                                        </>} />
                                    </>}
                                    onClose={onCLoseConfirmation}
                                    dataUI={getDataUI()}
                                >
                                    <Button
                                        dataUI={getDataUI()}
                                        icon="fal fa-trash"
                                        btnType="secondary"
                                        onClick={() => setShowConfirmation(true)}
                                    >
                                        Delete Image
                                    </Button>
                                </ConfirmationDialog>

                            </ImageCardPreview>
                        </ModalScrollableContainer>
                    </div>
                </div>
            </Modal>
        </ReactAux>
    )
}

export default DemoModalImageCard