/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Highlight from 'react-highlight';
import {
    ImageCard, ImageCardGrid, ImageCardPreview, ImageCardProps, formatFileSize,
    Toolbar, Button, FileUpload, ConfirmationDialog
} from '@jkhy/vsg-loanvantage-design-system';
import { Link } from 'react-router-dom';
import { getDataUI } from '../../helpers/helpers';

const ImageCardPage: React.FC = () => {
    const [selectDefault, setSelectDefault] = useState(false);
    const [selectIndicator, setSelectIndicator] = useState(false);
    const [imageCardList, setImageCardList] = useState([
        { src: `${process.env.PUBLIC_URL}/img/show-image.jpeg`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/vanguard-bank-logo.jpg`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/ion-bank-logo.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/ovation-bank-logo.jpg`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/ovation-global-dmc-logo.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/first-state-com-bank-logo.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: `${process.env.PUBLIC_URL}/img/JackHenryLending_LoanVantage_Logo_navy.png`, size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
    ]);
    const [infoItems, setInfoItems] = useState([
        { title: 'File name:', description: 'First_republic_bank.jpg' },
        { title: 'File Size:', description: '4.08 Mb' },
        { title: 'File Type:', description: 'image/png' },
        { title: 'URL:', description: 'https://loanvantage.dev/IBS/develop/publiccdn/api/branding/IBS/image/Equal_Housing_Logo.png', isLink: true },
    ]);
    const [selectedImage, setSelectedImage] = useState<ImageCardProps['image'] | null>(null);
    const [showConfigrm, setShowConfirmation] = useState(false)

    const onSelectImage = (image: ImageCardProps['image']) => {

        setSelectedImage(image);
        onSetPreviewItems(image);
    }

    const onDeleteImage = () => {
        setImageCardList(imageCardList.filter((image) => image.src !== selectedImage?.src))
        onUnselectImage();
        onCLoseConfirmation()
    }

    const onUnselectImage = () => {
        setSelectedImage(null)
    }

    const onCLoseConfirmation = () => {
        setShowConfirmation(false)
    }

    const onSetPreviewItems = (image: ImageCardProps['image']) => {
        setInfoItems([
            { title: 'File name:', description: image?.name || '' },
            { title: 'File Size:', description: image?.size || '' },
            { title: 'File Type:', description: image?.format || '' },
            { title: 'URL:', description: image?.src || '', isLink: true },
        ])
    }

    const onAddNewImage = (fileData: any) => {
        const { name, size, type } = fileData.file;

        const img = new Image();
        // set image url
        img.src = window.URL.createObjectURL(fileData.file);
        // get image dimentions
        img.onload = function () {
            const width = img.width;
            const height = img.height;
            const newImage = {
                src: img.src,
                name: name,
                size: formatFileSize(size),
                resolution: `${width}x${height}`,
                format: type
            };

            setSelectedImage(newImage);
            onSetPreviewItems(newImage);
            setImageCardList([
                newImage,
                ...imageCardList
            ])
        };

    }

    return (
        <div>
            <h1 className="mb-l">ImageCard</h1>
            <p className='mb-s'>Demo pages:</p>
            <ul className="mb-l">
                <li><Link to="/components/media-lib">Media Library</Link></li>
                <li><Link to="/components/media-lib-modal">Media Library in modal</Link></li>
            </ul>

            <h3 className="mb-s">Default</h3>
            <div className='d-flex mb-m'>
                <ImageCard
                    className='d-inline-flex'
                    onClick={() => {
                        setSelectDefault(!selectDefault)
                    }}
                    selected={selectDefault}
                    dataUI={getDataUI()}
                    image={{ src: `${process.env.PUBLIC_URL}/img/show-image.jpeg`, name: 'Image title', size: '2.5Mb', resolution: '1350x950', format: 'JPEG' }}
                />
            </div>

            <h3 className="mb-s">Image card with select indicator</h3>
            <div className='d-flex mb-m'>
                <ImageCard
                    className='d-inline-flex'
                    onClick={() => {
                        setSelectIndicator(!selectIndicator)
                    }}
                    selected={selectIndicator}
                    selectIndicator
                    dataUI={getDataUI()}
                    image={{ src: `${process.env.PUBLIC_URL}/img/show-image.jpeg`, name: 'Image title', size: '2.5Mb', resolution: '1350x950', format: 'JPEG' }}
                />
            </div>


            <h3 className="mb-s">Image Card in grid with preview and file upload</h3>
            <p className='mb-s'>Use <strong>ImageCardGrid</strong> component to wrap the image cards.</p>

            <div className='d-flex'>
                <ImageCardGrid dataUI={getDataUI()} className='mr-s'>
                    <FileUpload
                        dataUI={getDataUI()}
                        className="d-flex justify-content-center flex-column align-items-center"
                        visualType="large"
                        onChange={(files: any) => {
                            onAddNewImage(files[0])
                        }}
                        supportedFormats=".png,.jpg,.jpeg"
                        isMultiple
                    />
                    {
                        imageCardList.map((image, i) => {
                            return (
                                <ImageCard
                                    dataUI={getDataUI()}
                                    key={i}
                                    image={image}
                                    onClick={() => {
                                        onSelectImage(image);
                                    }}
                                    selected={image?.src === selectedImage?.src}
                                    onOutSideClick={() => {
                                        onUnselectImage()
                                    }}
                                />
                            )
                        })
                    }
                </ImageCardGrid>

                <ImageCardPreview
                    dataUI={getDataUI()}
                    title="Image Info"
                    imgSrc={selectedImage?.src}
                    infoItems={infoItems}
                >
                    <ConfirmationDialog
                        show={showConfigrm}
                        render={() => <>
                            <p className="mb-m">Are you sure you want to delete this image?</p>
                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                <Button onClick={onDeleteImage} btnType="primary" icon='fal fa-trash' className="mr-s" dataUI={getDataUI()}>
                                    Yes, Delete
                                </Button>
                                <Button onClick={onCLoseConfirmation} btnType="secondary" icon='fal fa-times-circle' className="mr-s" dataUI={getDataUI()}>
                                    Cancel
                                </Button>
                            </>} />
                        </>}
                        onClose={onCLoseConfirmation}
                        dataUI={getDataUI()}
                    >
                        <Button
                            dataUI={getDataUI()}
                            icon="fal fa-trash"
                            btnType="secondary"
                            onClick={() => setShowConfirmation(true)}
                        >
                            Delete Image
                        </Button>
                    </ConfirmationDialog>
                </ImageCardPreview>
            </div>

            
            <Highlight className="React">
                {
                    `
import React from 'react';
import { Button, Tooltip, } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {

    const [selectDefault, setSelectDefault] = useState(false);
    const [selectIndicator, setSelectIndicator] = useState(false);
    const [imageCardList, setImageCardList] = useState([
        { src: "\${process.env.PUBLIC_URL}/img/show-image.jpeg", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: "\${process.env.PUBLIC_URL}/img/vanguard-bank-logo.jpg", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: "\${process.env.PUBLIC_URL}/img/ion-bank-logo.png", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: "\${process.env.PUBLIC_URL}/img/ovation-bank-logo.jpg", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: "\${process.env.PUBLIC_URL}/img/ovation-global-dmc-logo.png", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: "\${process.env.PUBLIC_URL}/img/first-state-com-bank-logo.png", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
        { src: "\${process.env.PUBLIC_URL}/img/JackHenryLending_LoanVantage_Logo_navy.png", size: '2.5Mb', resolution: '1350x950', format: 'JPEG' },
    ]);
    const [infoItems, setInfoItems] = useState([
        { title: 'File name:', description: 'First_republic_bank.jpg' },
        { title: 'File Size:', description: '4.08 Mb' },
        { title: 'File Type:', description: 'image/png' },
        { title: 'URL:', description: 'https://loanvantage.dev/IBS/develop/publiccdn/api/branding/IBS/image/Equal_Housing_Logo.png', isLink: true },
    ]);
    const [selectedImage, setSelectedImage] = useState<ImageCardProps['image'] | null>(null);
    const [showConfigrm, setShowConfirmation] = useState(false)

    const onSelectImage = (image: ImageCardProps['image']) => {

        setSelectedImage(image);
        onSetPreviewItems(image);
    }

    const onDeleteImage = () => {
        setImageCardList(imageCardList.filter((image) => image.src !== selectedImage?.src))
        onUnselectImage();
        onCLoseConfirmation()
    }

    const onUnselectImage = () => {
        setSelectedImage(null)
    }

    const onCLoseConfirmation = () => {
        setShowConfirmation(false)
    }

    const onSetPreviewItems = (image: ImageCardProps['image']) => {
        setInfoItems([
            { title: 'File name:', description: image?.name || '' },
            { title: 'File Size:', description: image?.size || '' },
            { title: 'File Type:', description: image?.format || '' },
            { title: 'URL:', description: image?.src || '', isLink: true },
        ])
    }

    const onAddNewImage = (fileData: any) => {
        const { name, size, type } = fileData.file;

        const img = new Image();
        // set image url
        img.src = window.URL.createObjectURL(fileData.file);
        // get image dimentions
        img.onload = function () {
            const width = img.width;
            const height = img.height;
            const newImage = {
                src: img.src,
                name: name,
                size: formatFileSize(size),
                resolution: "\${width}x\${height}",
                format: type
            };

            setSelectedImage(newImage);
            onSetPreviewItems(newImage);
            setImageCardList([
                newImage,
                ...imageCardList
            ])
        };

    }


    return (
<ImageCard
        className='d-inline-flex'
        onClick={() => {
            setSelectDefault(!selectDefault)
        }}
        selected={selectDefault}
        // Set select indicator
        selectIndicator
        dataUI={getDataUI()}
        image={{ src: "\${process.env.PUBLIC_URL}/img/show-image.jpeg", name: 'Image title', size: '2.5Mb', resolution: '1350x950', format: 'JPEG' }}
/>

// Image Card in grid with preview and file upload
<div className='d-flex'>
    <ImageCardGrid dataUI={getDataUI()} className='mr-s'>
        <FileUpload
            dataUI={getDataUI()}
            className="d-flex justify-content-center flex-column align-items-center"
            visualType="large"
            onChange={(files: any) => {
                onAddNewImage(files[0])
            }}
            supportedFormats=".png,.jpg,.jpeg"
            isMultiple
        />
        {
            imageCardList.map((image, i) => {
                return (
                    <ImageCard
                        dataUI={getDataUI()}
                        key={i}
                        image={image}
                        onClick={() => {
                            onSelectImage(image);
                        }}
                        selected={image?.src === selectedImage?.src}
                        onOutSideClick={() => {
                            onUnselectImage()
                        }}
                    />
                )
            })
        }
    </ImageCardGrid>

    <ImageCardPreview
        dataUI={getDataUI()}
        title="Image Info"
        imgSrc={selectedImage?.src}
        infoItems={infoItems}
    >
        <ConfirmationDialog
            show={showConfigrm}
            render={() => <>
                <p className="mb-m">Are you sure you want to delete this image?</p>
                <Toolbar dataUI={getDataUI()} leftSide={<>
                    <Button onClick={onDeleteImage} btnType="primary" icon='fal fa-trash' className="mr-s" dataUI={getDataUI()}>
                        Yes, Delete
                    </Button>
                    <Button onClick={onCLoseConfirmation} btnType="secondary" icon='fal fa-times-circle' className="mr-s" dataUI={getDataUI()}>
                        Cancel
                    </Button>
                </>} />
            </>}
            onClose={onCLoseConfirmation}
            dataUI={getDataUI()}
        >
            <Button
                dataUI={getDataUI()}
                icon="fal fa-trash"
                btnType="secondary"
                onClick={() => setShowConfirmation(true)}
            >
                Delete Image
            </Button>
        </ConfirmationDialog>
    </ImageCardPreview>
</div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-s">API ImageCard</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>selected</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component selected state.</td>
                    </tr>
                    <tr>
                        <td>selectIndicator</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component state with select indicator.</td>
                    </tr>
                    <tr>
                        <td>image</td>
                        <td>
                            object with props: <br />
                            "src": string,<br />
                            "name"?: string,<br />
                            "format": string,<br />
                            "size": string,<br />
                            "resolution": string,<br />
                            "id"?: string | number<br />
                        </td>
                        <td className="text-center">-</td>
                        <td>Set component data. If image prop is not provided you can render children.</td>
                    </tr>
                    <tr>
                        <td>onOutSideClick</td>
                        <td>{'()=> void'}</td>
                        <td>false</td>
                        <td> Callback to execute when click outside of selected card.</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native div attributes like: "class", etc.</td>
                    </tr>
                </tbody>
            </table>

            <h2 className="mb-s">API ImageCardPreview</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets component title.</td>
                    </tr>
                    <tr>
                        <td>imgSrc</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets component preview image.</td>
                    </tr>
                    <tr>
                        <td>infoItems</td>
                        <td>
                            object with props: <br />
                            "title": string ,<br />
                            "description": string ,<br />
                            "isLink": boolean <br />
                        </td>
                        <td className="text-center">-</td>
                        <td>Set component info data. You can render additional info after the info items using children.</td>
                    </tr>
                    <tr>
                        <td>onOutSideClick</td>
                        <td>{'()=> void'}</td>
                        <td>false</td>
                        <td> Callback to execute when click outside of selected card.</td>
                    </tr>
                    <tr>
                        <td>emptyContainerConfig</td>
                        <td>
                            object with props: <br />
                            "title": string,<br />
                            "description": string <br />
                        </td>
                        <td className="text-center">{ "{title: 'Select an image to preview.', description: 'Get a preview and detailed info.'}"}</td>
                        <td>Set title and desct when no image selected.</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native div attributes like: "class", etc.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ImageCardPage;
