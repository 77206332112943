import React, { useState } from 'react';
import { Button, Modal, Toolbar, Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem, } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../../helpers/helpers';
import TeamsTab from './TeamsTab';

type EditUsersModalProps = {
    isVisible: boolean,
    onClose?(): void,
};

const EditUsersModal = (props: EditUsersModalProps) => {

    const { isVisible, onClose } = props;

    const [tabIndex, setTabindex] = useState(0);

    return (
        <Modal
            title="Edit ‘Samantha’"
            isVisible={isVisible}
            size="L+"
            onClose={onClose}
            dataUI={getDataUI()}
        >
            <Toolbar className="mb-s" dataUI={getDataUI()} leftSide={(
                <>
                    <Button className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save</Button>
                    <Button className="mr-s" btnType="secondary" icon="fal fa-copy" dataUI={getDataUI()}>Copy</Button>
                    <Button className="mr-s" btnType="secondary" icon="fal fa-times-circle" dataUI={getDataUI()}>Cancel</Button>
                    <Button btnType="secondary" icon="fal fa-trash" dataUI={getDataUI()}>Delete</Button>
                </>
            )}>
            </Toolbar>

            <Tabs
                onTabIndexChange={(index: number) => setTabindex(index)}
                currentlyActiveTabIndex={tabIndex}
                dataUI={getDataUI()}
            >
                <TabList dataUI={getDataUI()}>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Loan Officer</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Loan Assistant</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Portfolio Manager</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Approval Authority</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Post Approval - Authority</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Teams</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Branches</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Remote Access</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()}>
                    <TabContentItem dataUI={getDataUI()}>
                        <TeamsTab />
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Loan Officer</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Loan Assistant</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Portfolio Manager</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Approval Authority</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Post Approval - Authority</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <TeamsTab />
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Branches</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Remote Access</h1>
                    </TabContentItem>
                </TabContent>
            </Tabs>
        </Modal>
    );
};

export default EditUsersModal;