/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
    ReactAux, Main, MainContainer, LeftSideBar,
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import DemoSectionsForLeftSideBar from './DemoSectionsForLeftSideBar';
import DemoWizardNav from './DemoWizardNav';
import ComponentsTabs from './ComponentsTabs';
import DemoCreditCheck from './DemoCreditCheck';
import DemoLoanRequest from './DemoLoanRequest';
import DemoFloodDetermination from './DemoFloodDetermination';
import DemoScroll1 from './DemoScroll1';
import DemoScroll2 from './DemoScroll2';
import DemoScroll3 from './DemoScroll3';
import DemoScroll4 from './DemoScroll4';
import DemoScroll5 from './DemoScroll5';
import DemoScroll6 from './DemoScroll6';
import DemoScroll7 from './DemoScroll7';
import DemoModal from './DemoModal';
import DnDPresentationReport from './dnd-pages/DnDPresentationReport';
import DnDColumnSelection from './dnd-pages/DnDColumnSelection';
import DnDColumnSelectionTabs from './dnd-pages/DnDColumnSelectionTabs';
import DnDSimple from './dnd-pages/DnDSimple';
import DnDProcessDefinitionModal from './dnd-pages/DNDProcessDefinitionModal';
import DnDPrerenderedTabs from './dnd-pages/DnDPrerenderedTabs';
import DemoServiceRequest from './DemoServiceRequest';
import DemoFileCollateral from './DemoFileCollateral';
import DemoChatComponent from './DemoChatComponent';
import DemoEngagements from './DemoEngagements';
import DemoShowImage from './DemoShowImage';
import DemoSBA from './DemoSBA';
import DemoCovenant from './DemoCovenant';
import DemoRiskRating from './DemoRiskRating';
import DemoPolicyException from './DemoPolicyException';
import DemoApplicationCIBA from './DemoApplicationCIBA';
import DemoTicklerRecord from './DemoTicklerRecord';
import DemoFacilityDetails from './DemoFacilityDetails';
import DemoFeesClosing from './DemoFeesClosing';
import DemoRequiredDocuments from './DemoRequiredDocuments';
import DemoPageFileUploadTable from './DemoPageFileUploadTable';
import DemoRenewalsAndRefinance from './DemoRenewalsAndRefinance';
import { getDataUI } from '../helpers/helpers';
import DemoKendoGridFixColumns from './DemoKendoGridFixColumns';
import DemoPreWizard from './DemoPreWizard';
import DemoResizableSections from './DemoResizableSections';
import DemoPageErrorsInner from './DemoPageErrorsInner';
import DemoUpcomingOccurrences from './DemoUpcomingOccurrences';
import BusinessRulesModal from './BusinessRules/BusinessRulesModal';
import DemoOpenApi from './DemoOpenApi';
import DemoTabs from './DemoTabs';
import VirtualDnDProcessDefinitionModal from './dnd-pages/VirtualDNDProcessDefinitionModal';
import VirtualDnDSimple from './dnd-pages/VirtualDnDSimple';
import RolesTeamsUsersPage from './ RolesTeamsUsers/ RolesTeamsUsersPage';
import DnDSimpleNoDrag from './dnd-pages/DnDSimpleNoDrag';
import DemoMergeEmail from './DemoMergeEmail';
import DemoImageCard from './DemoImageCard';
import DemoModalImageCard from './DemoImageCardModal';

const ComponentsLayout = () => {

    const loaction = window.location;
    const isOnTicklerPage = loaction.pathname.includes('/components/tickler-record');
    const isOnResizableSectionsPage = loaction.pathname.includes('/demo/resizable-sections');
    const isOnFeesPage = loaction.pathname.includes('/components/fees-closing');
    const isOnOpenApiPage = loaction.pathname.includes('/components/open-api');
    const isMergeEmailPage = loaction.pathname.includes('/components/merge-email');
    const isImageCard = loaction.pathname.includes('/components/media-lib');

    return <ReactAux>
        <DemoHeader />
        <Main dataUI={getDataUI()} >
            {!isOnOpenApiPage && !isMergeEmailPage && !isOnTicklerPage && !isOnResizableSectionsPage && !isImageCard && <LeftSideBar dataUI={getDataUI()} isToggleable={(loaction.search !== '?wizard-nav' && loaction.pathname !== '/demo/loan-request')}>
                {(loaction.search === '?wizard-nav' || loaction.pathname === '/demo/loan-request') ? <DemoWizardNav /> : <DemoSectionsForLeftSideBar />}
            </LeftSideBar>}
            <MainContainer dataUI={getDataUI()} >
                <Switch>
                    <Route exact path="/components" component={ComponentsTabs} />
                    <Route path="/demo/resizable-sections" component={DemoResizableSections} />
                    <Route path="/demo/policy-exception" component={DemoPolicyException} />
                    <Route path="/demo/covenant" component={DemoCovenant} />
                    <Route path="/demo/sba" component={DemoSBA} />
                    <Route path="/demo/credit-check" component={DemoCreditCheck} />
                    <Route path="/demo/loan-request" component={DemoLoanRequest} />
                    <Route path="/demo/flood-determination" component={DemoFloodDetermination} />
                    <Route path="/demo/service-request" component={DemoServiceRequest} />
                    <Route path="/demo/file-collateral" component={DemoFileCollateral} />
                    <Route path="/demo/show-image" component={DemoShowImage} />
                    <Route path="/demo/required-docs" component={DemoRequiredDocuments} />
                    <Route path="/demo/fileupload-in-table" component={DemoPageFileUploadTable} />
                    <Route path="/demo/kendo-grid-fix-columns" component={DemoKendoGridFixColumns} />
                    <Route path="/demo/pre-wizard" component={DemoPreWizard} />
                    <Route path="/demo/engagements" component={DemoEngagements} />
                    <Route path="/demo/risk-rating" component={DemoRiskRating} />
                    <Route path="/demo/application-ciba" component={DemoApplicationCIBA} />
                    <Route path="/demo/scroll1" component={DemoScroll1} />
                    <Route path="/demo/scroll2" component={DemoScroll2} />
                    <Route path="/demo/scroll3" component={DemoScroll3} />
                    <Route path="/demo/scroll4" component={DemoScroll4} />
                    <Route path="/demo/scroll5" component={DemoScroll5} />
                    <Route path="/demo/scroll6" component={DemoScroll6} />
                    <Route path="/demo/scroll7" component={DemoScroll7} />
                    <Route path="/demo/modal" component={DemoModal} />
                    <Route path="/demo/401" component={() => <DemoPageErrorsInner status="401" />} />
                    <Route path="/demo/403" component={() => <DemoPageErrorsInner status="403" />} />
                    <Route path="/demo/404" component={() => <DemoPageErrorsInner status="404" />} />
                    <Route path="/demo/500" component={() => <DemoPageErrorsInner status="500" />} />
                    <Route path="/components/dnd/virtual-process-definition-modal" component={() => <VirtualDnDProcessDefinitionModal />} />
                    <Route path="/components/dnd/process-definition-modal" component={() => <DnDProcessDefinitionModal />} />
                    <Route path="/components/dnd/presentation-report-async" component={() => <DnDPresentationReport asyncData={true} />} />
                    <Route path="/components/dnd/presentation-report" component={() => <DnDPresentationReport asyncData={false} />} />
                    <Route path="/components/dnd/column-selection" component={DnDColumnSelection} />
                    <Route path="/components/dnd/column-selection-tabs-async" component={() => <DnDColumnSelectionTabs asyncData={true} />} />
                    <Route path="/components/dnd/column-selection-tabs" component={() => <DnDColumnSelectionTabs asyncData={false} />} />
                    <Route path="/components/dnd/prerendered-tabs" component={() => <DnDPrerenderedTabs />} />
                    <Route path="/components/dnd/virtual-simple" component={VirtualDnDSimple} />
                    <Route path="/components/dnd/no-dragg" component={DnDSimpleNoDrag} />
                    <Route path="/components/dnd/" component={DnDSimple} />
                    <Route path="/components/tickler-record" component={DemoTicklerRecord} />
                    <Route path="/components/open-api" component={DemoOpenApi} />
                    <Route path="/components/merge-email" component={DemoMergeEmail} />
                    <Route path="/components/facility-details" component={DemoFacilityDetails} />
                    <Route path="/components/fees-closing" component={DemoFeesClosing} />
                    <Route path="/components/renew-refinance" component={DemoRenewalsAndRefinance} />
                    <Route path="/components/upcoming-occurrences" component={DemoUpcomingOccurrences} />
                    <Route path="/components/business-rules" component={BusinessRulesModal} />
                    <Route path="/components/roles-teams-users" component={RolesTeamsUsersPage} />
                    <Route path="/components/tabs" component={DemoTabs} />
                    <Route path="/components/media-lib" component={DemoImageCard} />
                    <Route path="/components/media-lib-modal" component={DemoModalImageCard} />
                </Switch>
            </MainContainer>
            {(!isOnOpenApiPage && !isMergeEmailPage && !isOnTicklerPage && !isOnResizableSectionsPage && !isOnFeesPage && !isImageCard) && <DemoChatComponent />}
        </Main>
    </ReactAux>;
}

export default ComponentsLayout;