import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

const HeaderPage: React.FC = () => {

    return <div>
        <h1 className="mb-s">Header</h1>
        <p className="mb-s">The Header module exports components that compose the complex structure for the
            header section of <strong>Loan Vantage</strong>.</p>
        <p className="mb-l">Full Header example can be seen <a href='/components' title="header example">here</a>.</p>

        <h2 className="mb-m">Header module components:</h2>
        <p className="mb-s"><strong>Header</strong> - Wraps HeaderButtonIcon, HeaderSearch, DropdownMenu, HeaderMenu, HeaderRightSection components.</p>
        <p className="mb-s"><strong>HeaderSearch</strong> - Provides a specific search component for the Header. See <a href="#header-search">the detailed documentation</a>.</p>
        <p className="mb-s"><strong>HeaderMenu</strong> - Wraps DropdownMenu components.</p>
        <p className="mb-s"><strong><a href="#api-header-dropdown-menu">DropdownMenu</a></strong> - Wraps MenuItem and FacilityMenuItem components.</p>
        <p className="mb-s"><strong><a href="#api-header-menu-item">MenuItem</a></strong> - Nestable component that is rendered as a single item by default, but it can contain lists of other MenuItem components.</p>
        <p className="mb-s"><strong>FacilityMenuItem</strong> - Wraps FacilityList and FacilityFooter components.</p>
        <p className="mb-s"><strong>FacilityFooter</strong> - Wraps the content which stay at the bottom part of FacilityMenuItem.</p>
        <p className="mb-s"><strong>FacilityList</strong> - Wraps FacilityItem component.</p>
        <p className="mb-s"><strong><a href="#api-header-facility-item">FacilityItem</a></strong> - Displays information and wraps CollateralItem component.</p>
        <p className="mb-s"><strong><a href="#api-header-collateral-item">CollateralItem</a></strong> - Renders the content for collateral inside given FacilityItem.</p>
        <p className="mb-s"><strong><a href="#api-header-button-icon">HeaderButtonIcon</a></strong> - Represents the icon button with notification flags in the right section of the Header.</p>
        <p className="mb-s"><strong>HeaderRightSection</strong> - Wraps HeaderButtonIcon and HeaderInfo components and aligns them to the right side of the Header.</p>
        <p className="mb-xl"><strong><a href="#api-header-info">HeaderInfo</a></strong> - Contains the text information in the right section of the Header.</p>

        <Highlight className="React">
            {
                `
import React from 'react';
import { 
    Header, HeaderMenu, DropdownMenu, MenuItem, FacilityMenuItem, FacilityList, FacilityFooter,
    FacilityItem, CollateralItem, Button
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Example extends React.Component{
    state = {
        isOpenBar: false,
        isOpen: false,
        isFacilityOpen:false
    }
    render(){
        return (
            <Header>
                <HeaderButtonIcon icon="fal fa-home" dataUI={getDataUI()}/>

                // Dropdown menu with icon
                <DropdownMenu
                    dataUI={getDataUI()}
                    onToggleDropdownMenu={()=> this.setState({isOpenBar:!this.state.isOpenBar})}
                    isOpen={this.state.isOpenBar}
                    icon='fal fa-bars'
                >
                    <MenuItem 
                        dataUI={getDataUI()}
                        title={'Level 1'} 
                        avatar={{ src: 'https://www.w3schools.com/howto/img_avatar2.png', alt: 'avatarTest' }} 
                        onClick={(e) => console.log('avatar')}
                     />
                    <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-cog' }} isActive={true} />
                    <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-sign-out-alt', color: 'text-primary' }} />
                    <MenuItem dataUI={getDataUI()} title={'Dropdown Level 1'} >
                        <MenuItem dataUI={getDataUI()} title={'Level 2'} separator={true} />
                        <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                            <MenuItem title={'Level 3'} />
                            <MenuItem title={'Level 3'} />
                            <MenuItem title={'Level 3'} render={() => <span>Render Span</span>} />
                        </MenuItem>
                        <MenuItem dataUI={getDataUI()} title={'Level 2'} onClick={(e) => { console.log(e.target) }} />
                    </MenuItem>
                    <MenuItem  
                        dataUI={getDataUI()}
                        title={'Level 1'} 
                        icon={{ name: 'fal fa-palette', color: 'text-primary' }} 
                        render={() => <a href='/'>Render Link</a>} 
                    />
                </DropdownMenu>

                // HeaderSearch has a separate documentation page
                <HeaderSearch dataUI={getDataUI()}/>

                <HeaderMenu dataUI={getDataUI()}>

                    // Dropdown with FacilityMenuItem
                    <DropdownMenu
                        dataUI={getDataUI()}
                        title="Facility"
                        onToggleDropdownMenu={() => this.setState({ isFacilityOpen: !this.state.isFacilityOpen })}
                        isOpen={this.state.isFacilityOpen}
                    >
                        <FacilityMenuItem dataUI={getDataUI()}>
                            <FacilityList dataUI={getDataUI()}>
                                <FacilityItem
                                    price="9,000"
                                    loan={{ element: <a href="/">7777-2-L1111</a>, isActive: true }}
                                    collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    dataUI={getDataUI()}
                                >
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">White house</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">White house</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">White house</a>} />
                                </FacilityItem>
                                <FacilityItem
                                    price="8,500,000"
                                    loan={{ element: <a href="/">26CApprovalRequest</a> }}
                                    collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    dataUI={getDataUI()}
                                />
                                <FacilityItem
                                    price="21,266,000"
                                    loan={{ element: <a href="/">7777-6 - 26CApprovalRequest</a> }}
                                    collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    dataUI={getDataUI()}
                                >
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">L111 Collat for CAR reports</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">Titled -Motor Vehicles-Personal</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">CAP Rate Test</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">Test</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">Titled -Motor Vehicles-Personal</a>} />
                                    <CollateralItem dataUI={getDataUI()} render={() => <a href="/">CAP Rate Test</a>} />
                                </FacilityItem>
                            </FacilityList>
                            <FacilityFooter dataUI={getDataUI()}>
                                <Button dataUI={getDataUI()}>Add Facility</Button>
                            </FacilityFooter>
                        </FacilityMenuItem>
                    </DropdownMenu>
    
                    // Dropdown menu with text
                    <DropdownMenu
                        title={'Test'}
                        onToggleDropdownMenu={()=> this.setState({isOpen:!this.state.isOpen})}
                        isOpen={this.state.isOpen}
                        dataUI={getDataUI()}
                    >
                        <MenuItem title={'Level 1'} dataUI={getDataUI()} />
                        <MenuItem title={'Level 1'} dataUI={getDataUI()} >
                            <MenuItem title={'Level 2'} dataUI={getDataUI()}/>
                            <MenuItem title={'Level 2'} dataUI={getDataUI()} />
                        </MenuItem>
                        <MenuItem title={'Level 1'} dataUI={getDataUI()} />
                    </DropdownMenu>
    
                    // Dropdown menu render element
                    <DropdownMenu
                        dataUI={getDataUI()}
                        render={() => <span>Test 2</span>}
                    />
                </HeaderMenu>

                <HeaderRightSection dataUI={getDataUI()}>
                    <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-lock" notification={{title:"34", type:'warning'}}/>
                    <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-stamp" notification={{title:"6", type:'danger'}}/>
                    <HeaderInfo dataUI={getDataUI()} sync="03-01" user="IBS\\Stoil" version="3.19.25"/>
                </HeaderRightSection>
        </Header>
        );
    }
}
export default Example;
    `}
        </Highlight>

        <h2 className="mb-m">Header API</h2>

        <h3 id="api-header-dropdown-menu">DropdownMenu</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the title.</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the icon, title will be ignored.</td>
                </tr>
                <tr>
                    <td>isOpen</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Shows the expanded content.</td>
                </tr>
                <tr>
                    <td>onToggleDropdownMenu</td>
                    <td>void</td>
                    <td className="text-center">-</td>
                    <td>Callback when the component is clicked.</td>
                </tr>
                <tr>
                    <td>render</td>
                    <td>function</td>
                    <td className="text-center">-</td>
                    <td>Render custom element.<br />
                        <strong>Warning: only 'span' and 'a' will be styled acording to the Header design.</strong>
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 id="api-header-menu-item">MenuItem</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the title.</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td>Object with props:<br />
                        'name': set the icon,<br />
                        'color': utility class to set icon color</td>
                    <td className="text-center">-</td>
                    <td>Sets the icon before the title.</td>
                </tr>
                <tr>
                    <td>avatar</td>
                    <td>Object with props:<br />
                        'src': set the avatar img src,<br />
                        'alt': set avatar img alt</td>
                    <td className="text-center">-</td>
                    <td>
                        Sets the avatar. <br/>
                        *if empty string set to src, default avatar will be rendered.<br/>
                        *if broken url set to src, default avatar will be rendered.
                    </td>
                </tr>
                <tr>
                    <td>separator</td>
                    <td>boolean</td>
                    <td className="text-center">false</td>
                    <td>Sets darker border after the component, like the one when set avatar.</td>
                </tr>
                <tr>
                    <td>isActive</td>
                    <td>boolean</td>
                    <td className="text-center">-</td>
                    <td>Sets active MenuItem.</td>
                </tr>
                <tr>
                    <td>onClick</td>
                    <td>void</td>
                    <td className="text-center">-</td>
                    <td>Callback when the component is clicked.</td>
                </tr>
                <tr>
                    <td>render</td>
                    <td>function</td>
                    <td className="text-center">-</td>
                    <td>Render custom element.<br />
                        <strong>Warning: only 'span' and 'a' will be styled acording to the Header design.</strong>
                    </td>
                </tr>
                <tr>
                    <td>isHidden</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Hidde the MenuItem from the DOM.</td>
                </tr>
                <tr>
                    <td>noHoverEffect</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Removed hover effect.</td>
                </tr>
                <tr>
                    <td>onMouseLeave</td>
                    <td>void</td>
                    <td className="text-center">-</td>
                    <td>Callback when mouse leave from the MenuItem.</td>
                </tr>
            </tbody>
        </table>
        <h3 id="api-header-facility-item">FacilityItem</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>price</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the price.</td>
                </tr>
                <tr>
                    <td>loan</td>
                    <td>
                        Object with props: <br />
                        'element': JSX.Element, 'isActive': boolean
                    </td>
                    <td className="text-center">-</td>
                    <td>
                        Render custom element.<br />
                        <strong>Warning: only 'a' will be styled acording to the design.</strong>
                    </td>
                </tr>
                <tr>
                    <td>collateralSummary</td>
                    <td>
                        Object with props: <br />
                        'element': JSX.Element, 'isActive': boolean
                    </td>
                    <td className="text-center">-</td>
                    <td>
                        Render custom element.<br />
                        <strong>Warning: only 'a' will be styled acording to the design.</strong>
                    </td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-header-collateral-item">CollateralItem</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>render</td>
                    <td>Function</td>
                    <td className="text-center">-</td>
                    <td>
                        Render custom element.<br />
                        <strong>Warning: only 'a' will be styled acording to the design.</strong>
                    </td>
                </tr>
                <tr>
                    <td>isActive</td>
                    <td>string</td>
                    <td>false</td>
                    <td>Shows active state.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-header-button-icon">HeaderButtonIcon</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>icon</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the icon.</td>
                </tr>
                <tr>
                    <td>notification</td>
                    <td>
                        Object with props: <br />
                        'title': string, <br />
                        'type': 'success' | 'warning' | 'danger'
                    </td>
                    <td className="text-center">-</td>
                    <td>
                        Sets the notification. If the title is an empty string, a blank 
                        circle in the respective color will be rendered.
                    </td>
                </tr>
                <tr>
                    <td>All native button HTML attributes</td>
                    <td>ButtonHTMLAttributes</td>
                    <td className="text-center">-</td>
                    <td>Sets native attributes.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-header-info">HeaderInfo</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>sync</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the sync date.</td>
                </tr>
                <tr>
                    <td>user</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td> Sets the user.</td>
                </tr>
                <tr>
                    <td>version</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the version.</td>
                </tr>
            </tbody>
        </table>


        <h1 className="mb-s mt-xl" id="header-search">HeaderSearch</h1>

        <p className="mb-s">
            HeaderSearch component is an additional module to the Header module.
        </p>

        <p className="mb-l">HeaderSearch component has a specific architecture, that should be strictly followed in
        order to achieve its visual and functional design.
        </p>

        <h2 className="mb-m">HeaderSearch module components:</h2>
        <p className="mb-s"><strong><a href="#api-header-search">HeaderSearch</a></strong> - The main wrapper component.</p>
        <p className="mb-s"><strong>SearchForm</strong> - Only available child for HeaderSearch, along with SearchResults. Wraps the search form controls.</p>
        <p className="mb-s"><strong><a href="#api-search-input">SearchInput</a></strong> - Wrapper component of the input element.</p>
        <p className="mb-s"><strong>SearchMenu</strong> - Wrapper for the search submit button and the additional filters.</p>
        <p className="mb-s"><strong><a href="#api-search-filters">SearchFilters</a></strong> - A simple wrapper of the filter radio buttons.</p>
        <p className="mb-s"><strong><a href="#api-search-results">SearchResults</a></strong> - Only available child for HeaderSearch, along with SearchForm. Contains the components SearchResultsList and SearchResultsFooter.</p>
        <p className="mb-s"><strong>SearchResultsList</strong> - Wraps the components for potential search results.</p>
        <p className="mb-s"><strong>SearchResultsItem</strong> - Container for a single search result.</p>
        <p className="mb-s"><strong><a href="#api-search-details">SearchDetails</a></strong> - Creates the left column of a SearchResultsItem. Contains the essential information for the given search result.</p>
        <p className="mb-s"><strong>SearchDetailsList</strong> - Wrapper for SearchDetailsItems, that fill the right column of a SearchResultsItem.</p>
        <p className="mb-s"><strong><a href="#api-search-details-item">SearchDetailsItem</a></strong> - Container for individual items(files), that are related to the given search result.</p>
        <p className="mb-xl"><strong>SearchResultsFooter</strong> - A generic container for JSX elements in the bottom part of the SearchResults.</p>


        <p className="mb-s"><strong>Important: </strong>HeaderSearch does not execute search operations
                by itself. The architecture of the whole component module exposes all form controls, so that the state can
                be managed specificly by the consumer application.</p>
        <p className="mb-xl"><strong>Important: </strong>Since HeaderSearch is implemented as controlled component,
            its active state is completely managed by the parent component. All changes on this active state
            can be made safely through the specific callbacks, provided by HeaderSearch.
        </p>
        <p className="mb-m">A HeaderSearch example of the snippet below can be seen <Link to="/components">here</Link></p>


        <Highlight className="React">
            {
                `
import React from 'react';

// Third-party Link is for demonstration of links inside HeaderSearch
import { Link } from 'react-router-dom';

import {
    HeaderSearch, SearchForm, SearchInput, SearchMenu, SearchFilters,
    SearchResults, SearchResultsFooter, SearchResultsList, SearchResultsItem,
    SearchDetails, SearchDetailsList, SearchDetailsItem, Button, Select, ReactAux
} from '@jkhy/vsg-loanvantage-design-system';


const searchSelectOptions = [
    { value: "1", label: "Default" }, { value: "2", label: "File" }, 
    { value: "3", label: "Party Starts With" }, { value: "4", label: "Party Contains" }, 
    { value: "5", label: "Property Address" }
];

class Example extends React.Component {

    constructor(props) {
        super(props);
        
        // An example state, that handles basic actions for the HeaderSearch
        this.state = {
            hasSearchResults: false,
            searchInputValue: '',
            searchSelectValue: searchSelectOptions[0].value,
            searchRadioValue: '',
            headerSearchActive: false
        };
    }

    onSearchInputChange(event: any) {
        const newVal = event.target.value;
        this.setState({ searchInputValue: newVal });
    }

    onSearchSelectChange(obj: any) {
        const newVal = obj.value;
        this.setState({ searchSelectValue: newVal });
    }

    onSearchRadioChange(event: any) {
        const newVal = event.target.value;
        this.setState({ searchRadioValue: newVal });
    }

    onSearchSubmit() {
        const searchValue = this.state.searchInputValue;
        if (searchValue.trim().length > 0) {
            this.setState({ hasSearchResults: true })
        } else {
            this.setState({ hasSearchResults: false })
        }
    }

    activateHeaderSearch() {
        this.setState({ headerSearchActive: true });
    }

    deactivateHeaderSearch() {
        this.setState({ headerSearchActive: false });
    }

    render() {
        return (
            
            // Simplified Header example
            <Header>
                <HeaderButtonIcon icon="fal fa-home" />
                <DropdownMenu>...</DropdownMenu>

                <HeaderSearch
                    isActive={this.state.headerSearchActive}
                    onActivate={()=>this.activateHeaderSearch()}
                    onDeactivate={()=>this.deactivateHeaderSearch()}
                >
                    <SearchForm>
                        <SearchInput
                            placeholder="Search"
                            value={this.state.searchInputValue}
                            clearAction
                            onChange={e => this.onSearchInputChange(e)}
                            onSearchSubmit={() => this.onSearchSubmit()}
                            onClear={()=> console.log('Clear event')}
                        />
                        <SearchMenu>
                            <Select
                                value={this.state.searchSelectValue} 
                                onChange={(obj) => this.onSearchSelectChange(obj)} 
                                options={searchSelectOptions}
                                noMenuPortal
                             />
                            <Button onClick={() => this.onSearchSubmit()} icon="fal fa-search">Search</Button>
                            <SearchFilters title="Search in:">
                                <label>
                                    <input name="customer-type" type="radio" 
                                        checked={this.state.searchRadioValue === "Customers"} 
                                        onChange={e => this.onSearchRadioChange(e)} value="Customers" /> Customers
                                </label>
                                <label>
                                    <input name="customer-type" type="radio" 
                                        checked={this.state.searchRadioValue === "Prospects"} 
                                        onChange={e => this.onSearchRadioChange(e)} value="Prospects" /> Prospects
                                </label>
                                <label>
                                    <input name="customer-type" type="radio" 
                                        checked={this.state.searchRadioValue === "Leads"} 
                                        onChange={e => this.onSearchRadioChange(e)} value="Leads" /> Leads
                                </label>
                                <label>
                                    <input name="customer-type" type="radio" 
                                        checked={this.state.searchRadioValue === "Tenants"} 
                                        onChange={e => this.onSearchRadioChange(e)} value="Tenants" /> Tenants
                                </label>
                                <label>
                                    <input name="customer-type" type="radio" 
                                        checked={this.state.searchRadioValue === "All"} 
                                        onChange={e => this.onSearchRadioChange(e)} value="All" /> All
                                </label>
                            </SearchFilters>
                        </SearchMenu>
                    </SearchForm>
                    {
                        this.state.hasSearchResults &&
                        <SearchResults title={'65 results found for "Joro"'}>
                            <SearchResultsList>
                                <SearchResultsItem>
                                    <SearchDetails 
                                      title="test"
                                      icon={<Icon size="S" icon="fal fa-user"></Icon>}
                                      onClick={() => console.log('click')}
                                      titleAdditionalInfo="business"
                                    >
                                        Spread model: GMM, GMM
                                    </SearchDetails>
                                    <SearchDetailsList>
                                        <SearchDetailsItem 
                                          title="(12240) File Name"
                                          onClick={() => console.log('click')}
                                          officer="Officer"
                                          icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>}
                                        />
                                        <SearchDetailsItem 
                                          officer="Officer"
                                          render={<a href="./components">(12240) File Name</a>}
                                          icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>}
                                        />
                                    </SearchDetailsList>
                                </SearchResultsItem>

                                <SearchResultsItem>
                                    <SearchDetails 
                                      title="test"
                                      icon={<Icon size="S" icon="fal fa-user"></Icon>}
                                      onClick={() => console.log('click')}
                                      titleAdditionalInfo="business"
                                    >
                                        <p>test 1</p>
                                        <p>test 2</p>
                                        <p>test 3</p>
                                        Spread model: GMM, GMM
                                    </SearchDetails>
                                    <SearchDetailsList>
                                        <SearchDetailsItem 
                                          title="(12240) File Name"
                                          onClick={() => console.log('click')}
                                          officer="Officer"
                                        />
                                    </SearchDetailsList>
                                </SearchResultsItem>

                                <SearchResultsItem>
                                    <SearchDetails 
                                       render={<><a href="./components" onClick={() => console.log('click')}>Party</a>, some info</>} 
                                       icon={<Icon size="S" icon="fal fa-user"></Icon>}
                                    >
                                        Spread model: GMM, GMM
                                    </SearchDetails>
                                    <SearchDetailsList>
                                        <SearchDetailsItem 
                                           title="(12240) File Name"
                                           icon={<Icon size="S"
                                           icon="fal fa-people-arrows"></Icon>}
                                           onClick={() => console.log('click')} 
                                           officer="Officer"
                                        />
                                        <SearchDetailsItem 
                                           officer="Officer"
                                           icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>}
                                           render={<a href="./components">(12240) File Name</a>}
                                        />
                                        <SearchDetailsItem 
                                           title="Party name"
                                           icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>}
                                           onClick={() => console.log('click')}
                                        />
                                    </SearchDetailsList>
                                </SearchResultsItem>

                                <SearchResultsItem>
                                    <SearchDetails 
                                      title="test"
                                      icon={<Icon size="S" icon="fal fa-user"></Icon>}
                                      onClick={() => console.log('click')}
                                      titleAdditionalInfo="business"
                                    >
                                        Spread model: GMM, GMM
                                    </SearchDetails>
                                    <SearchDetailsList>
                                        <SearchDetailsItem 
                                         title="(12240) File Name"
                                         onClick={() => console.log('click')}
                                         officer="Officer"
                                         icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>}
                                        />
                                        <SearchDetailsItem 
                                           officer="Officer"
                                           render={<a href="./components">(12240) File Name</a>}
                                           icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>}
                                        />
                                    </SearchDetailsList>
                                </SearchResultsItem>

                                <SearchResultsItem>
                                    <SearchDetails 
                                      title="test"
                                      icon={<Icon size="S" icon="fal fa-user"></Icon>} 
                                      onClick={() => console.log('click')} 
                                      titleAdditionalInfo="business" 
                                    >
                                        <p>test 1</p>
                                        <p>test 2</p>
                                        <p>test 3</p>
                                        Spread model: GMM, GMM
                                    </SearchDetails>
                                    <SearchDetailsList>
                                        <SearchDetailsItem 
                                          title="(12240) File Name" 
                                          onClick={() => console.log('click')} 
                                          officer="Officer" 
                                        />
                                    </SearchDetailsList>
                                </SearchResultsItem>

                                <SearchResultsItem>
                                    <SearchDetails 
                                       render={<><a href="./components" 
                                       onClick={() => console.log('click')}>Party</a>, some info</>} 
                                       icon={<Icon size="S" icon="fal fa-user"></Icon>}
                                    >
                                        Spread model: GMM, GMM
                                    </SearchDetails>
                                    <SearchDetailsList>
                                        <SearchDetailsItem 
                                           title="(12240) File Name" 
                                           icon={<Icon size="S" 
                                           icon="fal fa-people-arrows"></Icon>} 
                                           onClick={() => console.log('click')} 
                                           officer="Officer" 
                                        />
                                        <SearchDetailsItem 
                                           officer="Officer" 
                                           icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} 
                                           render={<a href="./components">(12240) File Name</a>} 
                                        />
                                        <SearchDetailsItem 
                                          title="Party name" 
                                          icon={<Icon size="S" icon="fal fa-people-arrows"></Icon>} 
                                          onClick={() => console.log('click')} 
                                        />
                                    </SearchDetailsList>
                                </SearchResultsItem>

                            </SearchResultsList>
                            <SearchResultsFooter>
                                Didn't find the party you are searching for?
                            <Button className="ml-s" icon="fal fa-plus" btnType="secondary">Add party</Button>
                            </SearchResultsFooter>
                        </SearchResults>
                    }
                </HeaderSearch>

                <HeaderMenu>...</HeaderMenu>

                <HeaderRightSection>...</HeaderRightSection>
            </Header>
        );
    }
}

export default Example;
        `}
        </Highlight>

        <h2 className="mb-m">HeaderSearch API</h2>
        <p className="mb-xl">Some of the components in HeaderSearch module have React properties
                which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>

        <h3 id="api-header-search">HeaderSearch</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isActive</td>
                    <td>boolean</td>
                    <td>null</td>
                    <td>Sets the active state of HeaderSearch.</td>
                </tr>
                <tr>
                    <td>onActivate</td>
                    <td>() {'=>'} void</td>
                    <td>null</td>
                    <td>A callback to execute, when the HeaderSearch should be activated. This is
                        currently emitted after focus on the search input element. </td>
                </tr>
                <tr>
                    <td>onDeactivate</td>
                    <td>() {'=>'} void</td>
                    <td>null</td>
                    <td>A callback to execute, when the HeaderSearch should be deactivated (closed). This is
                        currently emitted after blur on the search input element. </td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-search-input">SearchInput</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>onSearchSubmit</td>
                    <td>() {'=>'} void</td>
                    <td>null</td>
                    <td>A callback to execute, when the 'Enter' key is pressed on the input.</td>
                </tr>
                <tr>
                    <td>clearAction</td>
                    <td className='text-center'>boolean</td>
                    <td className='text-center'>-</td>
                    <td>Sets clear action</td>
                </tr>
                <tr>
                    <td>onClear</td>
                    <td className='text-center'>{`() => void`}</td>
                    <td className='text-center'>-</td>
                    <td>Callback when clear action is clicked</td>
                </tr>
                <tr>
                    <td>all native input attributes</td>
                    <td className="text-center">-</td>
                    <td className="text-center">-</td>
                    <td>The SearchInput extends the native input element. Only the type attribute is available to override.</td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-search-filters">SearchFilters</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the text before the filter form controls (radio buttons).</td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-search-results">SearchResults</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the text above the listed search results. By design, it is expected
                    to show how many search results are found.
                            </td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-search-details">SearchDetails</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>The text for title. It will be rendered with link-like styles and
                                will therefore need a click handler.</td>
                </tr>
                <tr>
                    <td>titleAdditionalInfo</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Additional information text for the title. Will be separeted from the title by a comma.</td>
                </tr>
                <tr>
                    <td>onClick</td>
                    <td>(event?) {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>An on-click callback for the title.</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td>ReactNode</td>
                    <td className="text-center">-</td>
                    <td>A JSX element (Icon or Button with <i>btnType</i> of "icon"), that will be rendered before the title.</td>
                </tr>
                <tr>
                    <td>render</td>
                    <td>ReactNode</td>
                    <td className="text-center">-</td>
                    <td>Alternative way to manually pass the JSX elements (with click handlers, etc.),
                    to create the title. If this property is used, it takes priority over the
                    regular title configuration.
                            </td>
                </tr>
            </tbody>
        </table>

        <h3 id="api-search-details-item">SearchDetailsItem</h3>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>The text for title. It will be rendered with link-like styles and
                                will therefore need a click handler.</td>
                </tr>
                <tr>
                    <td>onClick</td>
                    <td>(event?) {'=>'} void</td>
                    <td className="text-center">-</td>
                    <td>An on-click callback for the title.</td>
                </tr>
                <tr>
                    <td>icon</td>
                    <td>ReactNode</td>
                    <td className="text-center">-</td>
                    <td>A JSX element (Icon or Button with <i>btnType</i> of "icon"), that will be rendered before the title.</td>
                </tr>
                <tr>
                    <td>render</td>
                    <td>ReactNode</td>
                    <td className="text-center">-</td>
                    <td>Alternative way to manually pass the JSX elements (with click handlers, etc.),
                    to create the title. If this property is used, it takes priority over the
                    regular title configuration.
                            </td>
                </tr>
                <tr>
                    <td>officer</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>Sets the secondary text below the title.</td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default HeaderPage;
